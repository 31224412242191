import '@/assets/admin-css/dashboard.css';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBox from "@/components/SkeletonBox";
import vmMachineApi from '@/api/gpuHub/vmMachines';
import gpuHubTransactionApi from '@/api/gpuHub/gpuHubTransaction';
import chartDataApi from '@/api/common/chartData';
import { mapState } from "vuex";
import Highcharts from 'highcharts'
import countryList from "@/constant/countryList"
import { colorGpuNodeColor, vmMachineStatus, vmMachineStatusColor } from "@/constant/vmMachineStatusConst";
import {
    areaSplineChart, totalRechargeOptions, barChartHoz, circleChart,
    colorSun, colorSat, colorNormal
} from "@/constant/dashboardChartOptions";
import { Chart } from 'highcharts-vue';
var jsonToPivotjson = require("json-to-pivot-json");
import moment from "moment";
var numeral = require('numeral'); 
var jwt = require("jsonwebtoken");
var METABASE_SITE_URL = "https://metabase.myrender.vn";
var METABASE_SECRET_KEY = "wkpySRvSg3Amax98FXt0j6AZueVBmVmxUeQ7fGD2PANWKzJAE3dk50d23qwhLU21hc3J2kDnffhU7n3QT2zf55mLCa9rqzNkvu04uWB8FF0udkTNVwTSXPz6Y6a4FEKdbPwnB47rvm8KZSPuMj00cDUdSa3S3MHAjbzdSbVVSnLadHVh8vGZTMTbEhd8r5hcJHrjSuxUFCwMY4nXcRy8Tj4uf1x5NKC3DcVCzUAPuUgCghKqHTGPb81AkpdZVr9WE1ErCCu7g0RjiR2SjZwNgCx1WtvbaGeTu6NKvEfmT4BpDr7zWvx3D8aTY51KbuBAFdKUrrJZKRZKd2dfJbE5qDvbYhPq6Luy7hMkYFF3RcBHZBBBaCxtf9qQgTNSrH2ZhmYtkKwDX9XdMNwBd8qu32V3LB9Rk78A16CBLTXHkWNfF2FfiauABd5mxW1ZA8f34LnH8ZG0e7tKMQ52cQqS2ZkWNCwme55p141MnVZdNCT2t7XPfurryWr4br3VdLTEamDk0TLKgW4wGmGT2B10JrUMd5ESuXFv3RkzdDE23MU3vcFWp8RGkMQ14mpQq1iCL0UQ2P1dZUEhrpD3yajDnxmGvNZ6uq0DLQaHhD7XpJJXXSBL2EHfNJc6qPkgnRrr";
var VUE_APP_METABASE_DASHBOARD = process.env.VUE_APP_METABASE_DASHBOARD === "true";
export default {
    extends: baseComponent,
    components: {
        highcharts: Chart,
        awsInfo: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/AwsInfo"),
            loading: SkeletonBox,
        }),
        statisticS3TransferOutByDate: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/StatisticS3TransferOutByDate"),
            loading: SkeletonBox,
        }), 
        statisticS3TransferOutS3TopCostByMonth: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/StatisticS3TransferOutS3TopCostByMonth"),
            loading: SkeletonBox,
        }), 
        revenueByMonthChart: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/RevenueByMonthChart"),
            loading: SkeletonBox,
        }), 
        countUserRechargeLevelByMonth: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/GetCountUserRechargeLevelByMonth"),
            loading: SkeletonBox,
        }), 
        spentOnPackageByMonth: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/SpentOnPackageByMonth"),
            loading: SkeletonBox,
        }), 
        rechargeAfterRegisterStatistics: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/RechargeAfterRegisterStatistics"),
            loading: SkeletonBox,
        }), 
        softwareInstalled: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/components/softwareInstalled"),
            loading: SkeletonBox,
        }), 
        proccessStatistics: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/components/proccessStatistics"),
            loading: SkeletonBox,
        }), 
        trackingCalendar: lazyLoadComponent({
            componentFactory: () => import("@/views2/system-tracking-calendar/index.vue"),
            loading: SkeletonBox,
        }),
        nasClientImageInfo : lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/components/nasClientImageInfo.vue"),
            loading: SkeletonBox,
        }),
        redshiftInfo: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/components/RedshiftInfo.vue"),
            loading: SkeletonBox,
        }),
        staffShift: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/components/staffShift.vue"),
            loading: SkeletonBox,
        }),
        lowbalanceReport: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/components/LowbalanceReport.vue"),
            loading: SkeletonBox,
        }),
        getRevenueByDateAccordingRechargeTime: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/RevenueByDateAccordingRechargeTime.vue"),
            loading: SkeletonBox,
        }),
        getUserByRegion: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/GetUserByRegion.vue"),
            loading: SkeletonBox,
        }),
        revenueByRegion: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/RevenueByRegion.vue"),
            loading: SkeletonBox,
        }),
        getUserByTop10Country: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/GetUserByTop10Country.vue"),
            loading: SkeletonBox,
        }),
        //AvgAmountOnRechargeTime
        avgAmountOnRechargeTime: lazyLoadComponent({
            componentFactory: () => import("@/views/dashboard/chartComponents/AvgAmountOnRechargeTime.vue"),
            loading: SkeletonBox,
        }),
    },
    data() {
        return {
            vueInstance: null,
            VUE_APP_METABASE_DASHBOARD: VUE_APP_METABASE_DASHBOARD,
            tabStyle: 'height: calc(100dvh - 22rem);',
            intervalUpdateChart : null,
            serverStatus: [],
            tabIndex: 0,
            tabCurrentTitle: `<i class="fas fa-user-plus"></i> New user`,
            tabList: [
                {
                    iconClass: 'fas fa-user-plus', tabName: 'New users', tabId: 'newUser', allowedShoDashboard: null,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 0;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
                {
                    iconClass: 'fas fa-users', tabName: 'Paid users', tabId: 'paidUser', allowedShoDashboard: true,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 1;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
                {
                    iconClass: 'far fa-calendar-alt', tabName: 'Revenue by month', tabId: 'revenuByMonth', allowedShoDashboard: true,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 2;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
                {
                    iconClass: 'fas fa-funnel-dollar', tabName: 'Recharged Registered', tabId: 'RechargeAfterRegisterStatistics', allowedShoDashboard: true,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 3;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
                {
                    iconClass: 'fas fa-map-marked-alt', tabName: 'Revenue by country', tabId: 'RevenueRegion', allowedShoDashboard: true,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 4;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.tab2Actived = true;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                        if (vueInstance.$refs.revenueByRegion) vueInstance.$refs.revenueByRegion.getRevenueByCountry();
                    }
                },
                {
                    iconClass: 'fas fa-history', tabName: 'Recharged level by month', tabId: 'RechargeLevelByMonth', allowedShoDashboard: true,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 5;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
                {
                    iconClass: 'el-icon-document', tabName: 'Package spent by month', tabId: 'spentOnPackageByMonth', allowedShoDashboard: true,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 6;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
                {
                    iconClass: 'fas fa-history', tabName: 'Avg Boot/Spent/Shutdown', tabId: 'AvgBootSpentShutdown', allowedShoDashboard: null,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 7;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
                {
                    iconClass: 'el-icon-pie-chart', tabName: 'Country statistics', tabId: 'RegionStatistics', allowedShoDashboard: null,
                    clickHandle(vueInstance, tabItem) {
                        vueInstance.tabIndex = 8;
                        vueInstance.tabCurrentTitle = tabItem.tabName;
                        vueInstance.selectTabsCommand({ cmdName: `<i class='${tabItem.iconClass}'></i> ${tabItem.tabName}`, targetId: tabItem.tabId });
                    }
                },
            ],
            tab2Actived: false,
            colorGpuNodeColor: colorGpuNodeColor,
            vmMachineStatus: vmMachineStatus,
            vmMachineStatusColor: vmMachineStatusColor,
            summaryInfo: {
                availableGPU: 0,
                availableCPU: 0,
                canBoot: false,
                canBootCpu: false,
                totalGPU: 0,
                totalCPU: 0,
                runningGPU: 0,
                runningCPU: 0,
            },
            totalRechage: {
                lastMonth: 0,
                lastMonthPoint: 0,
                thisMonth: 0,
                thisMonthPoint: 0,
                today: 0,
                todayPoint: 0,
                totalAmount: 0,
                totalPoint: 0,
            },
            numberOfDateView: 30,
            refreshClicked: false,
            userCreatedChart: {
                isLoading: true,
                targetCountry: "",
                options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)),
                {
                    legend: { enabled: true },
                    plotOptions: {
		                areaspline: {
			                dataLabels: {
				                enabled: true
			                },
			                marker: {
				                fillColor: Highcharts.getOptions().colors[1],
				                lineColor: Highcharts.getOptions().colors[1],
				                enabled: true
			                },
			                lineColor: '#303030'
		                },
		                series: {
			                tooltip: {
				                headerFormat: '{point.x}<br>',
				                pointFormat: '<b>{point.y} {series.name}</b>',
				                clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			                },
		                },
	                },
                }),
                tableData: [],
            },
            totalRechargeByStaffChart: {
                options: Object.assign({}, totalRechargeOptions),
                tableData: [],
            },
            userPaidChart: {
                isLoading: false,
                domesticOrInternational: 'all',
                options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                    chart : {
                        height: 550,
                        type: 'areaspline'
                    },
                    yAxis: [{ // Primary yAxis
                        labels: {
                            format: '{value} user(s)',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: 'Number of users',
                            style: {
                                color: Highcharts.getOptions().colors[1],
                                "font-size": 15,
                            }
                        }
                    }, { // Secondary yAxis
                        title: {
                            text: 'Total Amount',
                            style: {
                                "font-size": 15,
                            }
                        },
                        labels: {
                            format: '${value}',
                        },
                        opposite: true
                    },],
                    tooltip: {
                        shared: true
                    },
                    plotOptions: {
                        areaspline: {
                            dataLabels: {
                                enabled: true
                            },
                            fillOpacity: 0.5
                        }
                    },
                }),
                tableData: [],
            },
            //revenueRegionChart: {
            //    viewAllTime: true,
            //    options: Object.assign({}, barChartHoz),
            //    tableData: [],
            //},
            avgBootSpentShutdownChart: {
                isLoading: false,
                typeData: "average",
                options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                    chart: {
                        height: 550,
                        type: 'areaspline'
                    },
                    yAxis: [{ // Secondary yAxis
                        title: {
                            text: 'Total time (min)',
                            style: {
                                "font-size": 15,
                            }
                        },
                        labels: {
                            format: '{value} min',
                        },
                        opposite: true
                    }],
                    tooltip: {
                        shared: true
                    },
                    plotOptions: {
                        areaspline: {
                            dataLabels: {
                                enabled: true
                            },
                            fillOpacity: 0.5
                        }
                    },
                }),
                tableData: [],
            },
            //revenueRegionCircleChart: {
            //    options: Object.assign(circleChart,
            //    {
            //        plotOptions: {
            //            pie: {
            //                allowPointSelect: true,
            //                cursor: 'pointer',
            //                dataLabels: {
            //                    enabled: true,
            //                    format: '<b>{point.name}</b>: {point.percentage:.2f} %',
            //                },
            //                showInLegend: true
            //            }
            //        },
            //        series: [{
            //            name: 'Recharge($)',
            //            data: []
            //        }]
            //    }),
            //},
            countryList: countryList
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
        }),
        randomText() {
            const months = [
                "20% focus determines 80% of the results",
                "Life is trying things to see if they work",
                "Success is based on your own efforts. Never give up!",
                "Your efforts everyone can see. Keep the form to succeed soon",
            ];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        }, 
        metabaseUrl() {
            var payload = {
                resource: { dashboard: 1 },
                params: {},
                exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
            };
            var token = jwt.sign(payload, METABASE_SECRET_KEY);

            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false";
            return iframeUrl;
        }
    },
    mounted() {
        this.vueInstance = this;
        this.loadRentalServerStatus();
        this.getUserCreatedChartData();
        //this.getTotalRechargeByStaffChartData();
        this.getTotalRecharge();
        this.getCountPaidUserWithRecharge();
        this.getBootingSpentShutdownAvgStatisticsByDate();
        //this.getRevenueByCountry();
        this.intervalUpdateChart = setInterval(() => {
            this.refreshChartData();
            this.getTotalRecharge();
        }, 60000);
        this.$nextTick(() => {
            this.$set(this, "tabStyle", (this.rolesInfo.includes('OWNED') || this.rolesInfo.includes('QA')) ? 'height: calc(100dvh - 27rem);' : 'height: calc(100dvh - 20rem);');
        });
    },
    beforeDestroy() {
        clearInterval(this.intervalUpdateChart);
    },
    methods: {
        allowedShoDashboard() {
            return this.rolesInfo.includes('OWNED') || this.rolesInfo.includes('QA') || this.rolesInfo.includes('MARKETING_STAFF');// || [10].includes(this.userInfo.roleId);
        },
        activaTab(tabId, tab) {
            $('#' + tabId + '.nav-tabs a[href="#' + tab + '"]').tab('show');
        },
        selectTabsCommand(data) {
            console.log(data);
            this.tabCurrentTitle = data.cmdName;
            this.activaTab('chartTabs', data.targetId);
        },
        loadRentalServerStatus() {
            vmMachineApi.getAllServerReport().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.serverStatus = response.data.data.data.map(item => {
                        return {
                            "package": item.package,
                            "total": item.total,
                            "available": item.available,
                            "status_text": item.statusText
                        };
                    });
                    this.hideLoading();
                }
                else {
                    this.serverStatus = [];
                    if (response.data  && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.serverStatus = [];
            });
        },
        getTotalRecharge() {
            gpuHubTransactionApi.getTotalRecharge().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.$set(this, "totalRechage", response.data.data);
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        proccessingEventMachine(message) {
            switch (message.cmdName) {
                case "UpdateServerStatus":
                    if (message.data !== null) this.$set(this, "serverStatus", message.data);
                    break;
                case "UpdateSummaryInfo":
                    if (message.data !== null) this.$set(this, "summaryInfo", message.data);
                    break;
            }
        },
        refreshChartData() {
            this.loadRentalServerStatus();
            this.getUserCreatedChartData();
            //this.getTotalRechargeByStaffChartData();
            this.getCountPaidUserWithRecharge();
            //if (this.tab2Actived) this.getRevenueByCountry();
            this.getBootingSpentShutdownAvgStatisticsByDate();
            if (this.$refs.revenueByRegion && this.$refs.revenueByRegion.getRevenueByCountry) this.$refs.revenueByRegion.getRevenueByCountry();
            if (this.$refs.userbyRegion && this.$refs.userbyRegion.getRevenueByCountry) this.$refs.userbyRegion.getRevenueByCountry();
            if (this.$refs.userbyRegion && this.$refs.userbyRegion.getUserByCountry) this.$refs.userbyRegion.getUserByCountry();
        },
        getUserCreatedChartData() {
            this.userCreatedChart.isLoading = true;
            chartDataApi.getUserCreatedChartData(this.numberOfDateView, this.userCreatedChart.targetCountry).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.userCreatedChart.tableData = response.data.data;
                    this.userCreatedChart.options.xAxis.categories = [];
                    this.userCreatedChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.userCreatedChart.options.series = [{
                        name: "User Registered",
                        data: response.data.data.map(x => {
                            return {
                                y: x.countUser,
                                marker: {
                                    //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                    fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                }
                            }
                        })
                    },
                    {
                        type: 'column',
                        name: "User has created image",
                        data: response.data.data.map(x => {
                            return {
                                y: x.createdImageCount,
                                marker: {
                                    //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                    fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                }
                            }
                        }),
                        dataLabels: {
                            enabled: true
                        }
                    },
                    {
                        type: 'column',
                        name: "User has boot",
                        data: response.data.data.map(x => {
                            return {
                                y: x.bootCount,
                                marker: {
                                    //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                    fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                }
                            }
                        }),
                        dataLabels: {
                            enabled: true
                        }
                    }];
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
                this.userCreatedChart.isLoading = false;
            }).catch(error => {
                console.error(error);
                this.userCreatedChart.isLoading = false;
            });
        },
        getTotalRechargeByStaffChartData() {
            chartDataApi.getTotalRechargeByStaffChartData(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    var options = {
                        row: "createdAt",
                        column: "staff",
                        value: "totalAmount"
                    };
                    let uniqueStaff = response.data.data.map((item) => {
                        return item.staff;
                    }).filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
                    var output = jsonToPivotjson(response.data.data, options);
                    this.totalRechargeByStaffChart.tableData = output;
                    this.totalRechargeByStaffChart.options.xAxis.categories = [];
                    this.totalRechargeByStaffChart.options.xAxis.categories = output.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.totalRechargeByStaffChart.options.series = [];
                    for (let i = 0; i < uniqueStaff.length; i++) {
                        this.totalRechargeByStaffChart.options.series.push({
                            name: uniqueStaff[i],
                            data: output.map(x => {
                                return {
                                    y: x[uniqueStaff[i]],
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                };
                            })
                        });
                    }
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getCountPaidUserWithRecharge() {
            this.userPaidChart.isLoading = true;
            chartDataApi.getCountPaidUserWithRecharge(this.userPaidChart.domesticOrInternational, this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.userPaidChart.tableData = response.data.data;
                    this.userPaidChart.options.xAxis.categories = [];
                    this.userPaidChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.userPaidChart.options.series = [
                        {
                            name: "Amount recharge",
                            yAxis: 1,
                            type: 'column',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: '$'
                            },
                            data: response.data.data.map(x => {
                                return {
                                    y: x.totalAmount,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        },
                        {
                            name: "Amount spent",
                            yAxis: 1,
                            type: 'column',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: '$'
                            },
                            data: response.data.data.map(x => {
                                return {
                                    y: x.totalSpentAmount,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        },
                        {
                            name: "Paid user",
                            type: 'spline',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valueSuffix: ' user(s)'
                            },
                            data: response.data.data.map(x => {
                                return {
                                    y: x.countUser,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        }];
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
                this.userPaidChart.isLoading = false;
            }).catch(error => {
                console.error(error);
                this.userPaidChart.isLoading = false;
            });
        },
        getBootingSpentShutdownAvgStatisticsByDate() {
            if (this.refreshClicked) this.avgBootSpentShutdownChart.isLoading = true;
            this.refreshClicked = false;
            chartDataApi.getBootingSpentShutdownAvgStatisticsByDate(this.avgBootSpentShutdownChart.typeData, this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.avgBootSpentShutdownChart.tableData = response.data.data;
                    this.avgBootSpentShutdownChart.options.xAxis.categories = [];
                    this.avgBootSpentShutdownChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.dateTarget).format('DD-MM-YYYY'));
                    this.avgBootSpentShutdownChart.options.series = [
                        {
                            name: "Boot duration (minute)",
                            type: 'column',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: ''
                            },
                            color: '#FFCF07',
                            data: response.data.data.map(x => {
                                return {
                                    y: x.avgBootDuration,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.dateTarget).day() === 0) ? colorSun : ((moment.utc(x.dateTarget).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        },
                        {
                            name: "Shutdown duration (minute)",
                            type: 'column',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: ''
                            },
                            color: '#EA0505',
                            data: response.data.data.map(x => {
                                return {
                                    y: x.avgShutdownDuration,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.dateTarget).day() === 0) ? colorSun : ((moment.utc(x.dateTarget).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        },
                        {
                            name: "Spent duration (minute)",
                            type: 'spline',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: ''
                            },
                            color: '#23BB97',
                            lineWidth: 3,
                            data: response.data.data.map(x => {
                                return {
                                    y: x.avgSpentDuration,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.dateTarget).day() === 0) ? colorSun : ((moment.utc(x.dateTarget).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        },];
                    this.avgBootSpentShutdownChart.isLoading = false;
                }
                else {
                    this.avgBootSpentShutdownChart.isLoading = false;
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                this.avgBootSpentShutdownChart.isLoading = false;
                console.error(error);
            });
        },
        arrayRechargeToHTMLTable(myArray) {
            if (myArray.length > 0) {
                var result = "<table class='table table-bordered table-striped'>";
                let keyList = Object.keys(myArray[0]);
                //Create header
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Date</strong></h4></td>`;
                for (let j = 0; j < this.totalRechargeChart.providerList.length; j++) {
                    result += `<td class="text-right p-1"><h5 class="mb-0"><strong>${this.totalRechargeChart.providerList[j]}</strong></h4></td>`;
                }
                result += "</tr>";
                for (let i = 0; i < myArray.length; i++) {
                    result += "<tr>";
                    result += `<td class="text-center p-1"><h5 class="mb-0">${moment.utc(myArray[i][keyList[0]]).format('DD-MM-YYYY')}</h5></td>`;
                    for (let j = 0; j < this.totalRechargeChart.providerList.length; j++) {
                        result += `<td  class="text-right p-1">
                                    <h5 class="mb-0">${(myArray[i][this.totalRechargeChart.providerList[j]] ?
                            (myArray[i][this.totalRechargeChart.providerList[j]] > 0 ? '$' + myArray[i][this.totalRechargeChart.providerList[j]] : "-") : "-")}</h5>
                            </td>`;
                    }
                }
                //Create foooter
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Total</strong></h4></td>`;
                for (let j = 0; j < this.totalRechargeChart.providerList.length; j++) {
                    let sumVal = myArray.reduce((total, num) => { return total + num[this.totalRechargeChart.providerList[j]]; }, 0);
                    result += `<td class="text-right p-1"><h5 class="mb-0"><strong>${numeral(sumVal).format('0,0[.][0000]')}</strong></h4></td>`;
                }
                result += "</table>";
                return result;
            }
            else return "";
        },
        arrayToHTMLTable(myArray) {
            if (myArray.length > 0) {
                var result = "<table class='table table-bordered table-striped'>";
                let keyList = Object.keys(myArray[0]);
                //Create header
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Date</strong></h4></td>`;
                for (var j = 0; j < this.serverStatus.length; j++) {
                    result += `<td class="text-center p-1"><h5 class="mb-0"><strong>${this.$store.state.gpuNodeName[this.serverStatus[j].package].name}</strong></h4></td>`;
                }
                result += "</tr>";
                for (var i = 0; i < myArray.length; i++) {
                    result += "<tr>";
                    result += `<td class="text-center p-1"><h5 class="mb-0">${moment.utc(myArray[i][keyList[0]]).format('DD-MM-YYYY')}</h5></td>`;
                    for (j = 0; j < this.serverStatus.length; j++) {
                        result += `<td  class="text-center p-1">
                                    <h5 class="mb-0">${(myArray[i][this.serverStatus[j].package] ?
                                (myArray[i][this.serverStatus[j].package] > 0 ? myArray[i][this.serverStatus[j].package] + ' times' : "-") : "-")}</h5>
                            </td>`;
                    }
                }
                //Create foooter
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Total</strong></h4></td>`;
                for (let j = 0; j < this.serverStatus.length; j++) {
                    let sumVal = myArray.reduce((total, num) => { return total + num[this.serverStatus[j].package]; }, 0);
                    result += `<td class="text-center p-1"><h5 class="mb-0"><strong>${numeral(sumVal).format('0,0[.][0000]')}${sumVal > 0 ? ' times' : ' time'}</strong></h4></td>`;
                }
                result += "</table>";
                return result;
            }
            else return "";
        }   
    }
}