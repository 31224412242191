import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=2aa51a58&scoped=true&"
import script from "@/scripts/dashboard/dashboard.js?vue&type=script&lang=js&"
export * from "@/scripts/dashboard/dashboard.js?vue&type=script&lang=js&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=2aa51a58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa51a58",
  null
  
)

export default component.exports